<template>
    <!-- 菜单页 -->
    <div id="app_index">
        <!-- 头 -->
        <el-row type="flex" align="middle" class="head_top" style="border-bottom: 3px solid #4cb926;">
            <el-col :span="14" style="text-align:left;padding-left:10px;">
                <span class="font_size3">企信通短信管理平台</span>
                <el-link type="primary" :underline="false" v-if="gj_power_level_b" style="margin-left:10px;" @click="gjdx_jump()">国际</el-link>
            </el-col>
            <el-col :span="10"  style="text-align:right;padding-right:10px;color: #4cb926;font-weight: normal;">
                <span class="font_size3">Hi,{{qxt_username}}</span>
                <el-link @click="quit()" type="primary" :underline="false" style="margin-left:10px;color: #4cb926;">[退出]</el-link>
            </el-col>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                <template v-for="item in showLevleList">
                    <el-row style="margin-top:30px;">
                        <el-col :span="8">
                            <template v-if="item.length>0">
                                <el-row @click.native="jump_url(item[0])" class="cursor" type="flex" align="middle" justify="center" style="display: inline-block;width:100%;text-align:center">
                                    <el-image  style="width:70px;height: 70px" :src="item[0].menu_img_url" fit="fill" ></el-image>
                                    <div style="text-align:center">{{item[0].menu_name}}</div>
                                </el-row>
                            </template>
                        </el-col>
                        <el-col :span="8">
                            <template v-if="item.length>1">
                                <el-row @click.native="jump_url(item[1])" class="cursor" type="flex" align="middle" justify="center" style="display: inline-block;width:100%;text-align:center">
                                    <el-image  style="width:70px;height: 70px" :src="item[1].menu_img_url" fit="fill" ></el-image>
                                    <div style="text-align:center">{{item[1].menu_name}}</div>
                                </el-row>
                            </template>

                        </el-col>
                        <el-col :span="8">
                            <template v-if="item.length>2">
                                <el-row @click.native="jump_url(item[2])" class="cursor" type="flex" align="middle" justify="center" style="display: inline-block;width:100%;text-align:center">
                                    <el-image  style="width:70px;height: 70px" :src="item[2].menu_img_url" fit="fill" ></el-image>
                                    <div>{{item[2].menu_name}}</div>
                                </el-row>
                            </template>

                        </el-col>

                    </el-row>
                </template>

            </el-row>
            

        </el-row>
        

    </div>
</template>
<script>
import {
    setItem,
    getItem,
    setCookie,
    getCookie
} from '../api/tool.js'
import API from '../api/api';

export default {


    
    data(){
        return{
            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            qxt_username:'',//登录账号
            qxt_power_level:'',//权限

            showLevleList:[],//展示的权限的模块数组

            gj_power_level_b:false,//是否展示国际权限
            
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

        console.log("测试："+sessionStorage.getItem("qxt_session"));
    },
    activated() {
        console.log("主菜单");
        //重置所有数据
        //Object.assign(this.$data, this.$options.data.call(this));
        //this.form_data = this.$options.data().form_data;//单个恢复初始化

        this.getData();//获取数据
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 90);

            this.qxt_username = getItem("qxt_username");//登录账号
            this.qxt_power_level = getItem("power_level");//权限
            if(API.isEmtry(this.qxt_power_level)){

                // this.qxt_username = getCookie("qxt_username");
                this.qxt_power_level = getCookie("qxt_power_level");//权限

                // setItem("qxt_username", this.qxt_username);
                // setItem("power_level",this.qxt_power_level);
                // setItem("qxt_session",getCookie("qxt_session"));

                // if(API.isEmtry(this.qxt_power_level)){
                //      API.userLogin();//跳转到登录页
                // }
               
            }
            console.log("权限getItem:"+this.qxt_power_level);
            
        },
        //获取数据
        getData(){
            var power_levels = this.qxt_power_level.split(",");//权限 数组
            var p_list = [];
            for (var i = 0; i < power_levels.length; i++) {
                var power_level = power_levels[i];//
                if(power_level == 66){//国际权限
                    this.gj_power_level_b = true;
                }
                if(power_level == 1){
                    var menu_img_url = require("@/assets/img/menu/yonghu.png");//菜单图片地址
                    var menu_name = "客户管理";//菜单名称
                    var menu_url_type = 0;//0:路由  1:跳转地址
                    var menu_url = "/user_index";//菜单上的访问地址

                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 2){
                    var menu_img_url = require("@/assets/img/menu/tongdao.png");//菜单图片地址
                    var menu_name = "通道管理";   //菜单名称
                    var menu_url_type = 0;       //0:路由  1:跳转地址
                    var menu_url = "/channel_index";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 53){
                    var menu_img_url = require("@/assets/img/menu/tongdao.png");//菜单图片地址
                    var menu_name = "运维管理";   //菜单名称
                    var menu_url_type = 0;       //0:路由  1:跳转地址
                    var menu_url = "/operation_index";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);

                }else if(power_level == 54){
                    var menu_img_url = require("@/assets/img/menu/tongdao.png");//菜单图片地址
                    var menu_name = "未知状态转化";   //菜单名称
                    var menu_url_type = 0;       //0:路由  1:跳转地址
                    var menu_url = "/unknown_state_change_list";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);

                }else if(power_level == 67){
                    var menu_img_url = require("@/assets/img/menu/tongdao.png");//菜单图片地址
                    var menu_name = "系统限制";   //菜单名称
                    var menu_url_type = 0;       //0:路由  1:跳转地址
                    var menu_url = "/index_system_control";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);

                }else if(power_level == 3){
                    var menu_img_url = require("@/assets/img/menu/qianming.png");//菜单图片地址
                    var menu_name = "签名管理";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/signManage/signIndex.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 4){
                    var menu_img_url = require("@/assets/img/menu/sx_chaxun.png");//菜单图片地址
                    var menu_name = "短信查询";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/upDownSearch/upDownNavigation.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 5){
                    var menu_img_url = require("@/assets/img/menu/duanxin.png");//菜单图片地址
                    var menu_name = "审核管理";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/checkManage/navigationb.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 6){
                    var menu_img_url = require("@/assets/img/menu/chongzhijilu.png");//菜单图片地址
                    var menu_name = "充值记录";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/recharge/find.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 7){
                    var menu_img_url = require("@/assets/img/menu/shujujiance.png");//菜单图片地址
                    var menu_name = "数据监测";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/dataMonitor/navigation.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 8){
                    var menu_img_url = require("@/assets/img/menu/shujutongji.png");//菜单图片地址
                    var menu_name = "高级数据统计";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/DataStatistics/DataStatistics.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 9){
                    var menu_img_url = require("@/assets/img/menu/haoduan.png");//菜单图片地址
                    var menu_name = "号段管理";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/sectionNo/index.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 10){
                    var menu_img_url = require("@/assets/img/menu/heihongmingdan.png");//菜单图片地址
                    var menu_name = "黑-红名单管理";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/blackRed/blackRed.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 11){
                    var menu_img_url = require("@/assets/img/menu/zi.png");//菜单图片地址
                    var menu_name = "过滤字管理";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/FilterManage/FilterManageNavigations.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 16){
                    var menu_img_url = require("@/assets/img/menu/kefuguanli.png");//菜单图片地址
                    var menu_name = "运营人员管理";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/EsqManage/index.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 17){
                    var menu_img_url = require("@/assets/img/menu/label.png");//菜单图片地址
                    var menu_name = "标签";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/label/label.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 19 || power_level == 20 || power_level == 21){
                    var menu_img_url = require("@/assets/img/menu/caiwu.png");//菜单图片地址
                    var menu_name = "财务中心";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/finance/financeJob.html?tap=0";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 22){
                    var menu_img_url = require("@/assets/img/menu/tousu.png");//菜单图片地址
                    var menu_name = "投诉";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/complaintsManage/complaintsIndex.html?tap=0";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 23){
                    var menu_img_url = require("@/assets/img/menu/gonggao.png");//菜单图片地址
                    var menu_name = "公告";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/announce/addAnnounce.html?tap=0";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 24){
                    var menu_img_url = require("@/assets/img/menu/menu_tingting.png");//菜单图片地址
                    var menu_name = "婷婷管理";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/tingTingQXT/tingtingLevel.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 25){
                    var menu_img_url = require("@/assets/img/menu/caiwu.png");//菜单图片地址
                    var menu_name = "账期统计";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/financeCount/count.html?tap=0";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 34){
                    var menu_img_url = require("@/assets/img/menu/menu_nei_xun_huan.png");//菜单图片地址
                    var menu_name = "内循环管理";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/imitate/imitateList.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 35){
                    var menu_img_url = require("@/assets/img/menu/zhangdan.png");//菜单图片地址
                    var menu_name = "运营人员考核";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/xuzong/select.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 36){
                    var menu_img_url = require("@/assets/img/menu/AI.png");//菜单图片地址
                    var menu_name = "AI训练";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/AI/ai_user_check.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 37){
                    var menu_img_url = require("@/assets/img/menu/AI.png");//菜单图片地址
                    var menu_name = "AI训练管理";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/AI/ai_manage_check.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 40){
                    var menu_img_url = require("@/assets/img/menu/shujujiance.png");//菜单图片地址
                    var menu_name = "高级数据监测";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/dataMonitorHigh/navigation.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 41){
                    var menu_img_url = require("@/assets/img/menu/dataconfig.png");//菜单图片地址
                    var menu_name = "数据管理";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/export/index.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 56){
                    var menu_img_url = require("@/assets/img/menu/yuyin.png");//菜单图片地址
                    var menu_name = "语音报备管理";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/Voicereporting/Voiceindex.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 57){
                    var menu_img_url = require("@/assets/img/menu/channellist.png");//菜单图片地址
                    var menu_name = "通道列表";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/ChannelList/channelList.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 58){
                    var menu_img_url = require("@/assets/img/menu/zd.png");//菜单图片地址
                    var menu_name = "账单管理";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/billingSystem/index.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 60){
                    var menu_img_url = require("@/assets/img/menu/link_img.png");//菜单图片地址
                    var menu_name = "链接管理";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/linkconfig/index.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 61){
                    var menu_img_url = require("@/assets/img/menu/lh.png");//菜单图片地址
                    var menu_name = "灵狐预审域名配置";   //菜单名称
                    var menu_url_type = 1;       //0:路由  1:跳转地址
                    var menu_url = "../wap/html/manage/lhConfig/index.html";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 63){
                    var menu_img_url = require("@/assets/img/menu/media.png");//菜单图片地址
                    var menu_name = "多媒体消息";   //菜单名称
                    var menu_url_type = 0;       //0:路由  1:跳转地址
                    var menu_url = "/media";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 64){
                    var menu_img_url = require("@/assets/img/menu/tongdao.png");//菜单图片地址
                    var menu_name = "控价管理";   //菜单名称
                    var menu_url_type = 0;       //0:路由  1:跳转地址
                    var menu_url = "/control_index";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }else if(power_level == 65){
                    var menu_img_url = require("@/assets/img/menu/tongdao.png");//菜单图片地址
                    var menu_name = "实时数据";   //菜单名称
                    var menu_url_type = 0;       //0:路由  1:跳转地址
                    var menu_url = "/index_real_data";//菜单上的访问地址
                    var obj = {menu_img_url:menu_img_url,menu_name:menu_name,menu_url_type:menu_url_type,menu_url:menu_url};
                    p_list.push(obj);
                }
            }
            this.showLevleList = [];
            var showLevleList2 = new Array();
            for (var i = 0,len=p_list.length; i < len; i++) {
                showLevleList2.push(p_list[i]);
                if(showLevleList2.length == 3 || i == len-1){

                    this.showLevleList.push(showLevleList2);
                    showLevleList2 = new Array();
                }
            }
            

        },
        //国际
        gjdx_jump(){
           //路由
            API.router_to("/inter_index");
           
        },
        //跳转地址
        jump_url(item){
            var menu_url_type = item.menu_url_type;//0:路由  1:跳转地址
            var menu_url = item.menu_url;//菜单上的访问地址
            var menu_name = item.menu_name;//菜单名称

            console.log("跳转地址:"+menu_url);
            if(menu_url_type == 0){//0:路由  1:跳转地址
                API.router_to(menu_url);
            }else{
                if(menu_name === "账单管理"){
                    setCookie("bill_type", "58");
                }else if(menu_name === "高级账单管理"){
                    setCookie("bill_type", "59");
                }
                //console.log("跳转地址2222:"+menu_url_type);
                location.href = menu_url;
            }
        },

        //退出
        quit(){
            //跳转到登录页面
            API.userLogin();
        },
    }
};

</script>

<style scoped>

</style>